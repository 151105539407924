import { ConfigHeader } from "../../types/config";
import * as Icons from "./icons/socialIcon";

export const configHeader: ConfigHeader = {
  megaMenu: {
    newsletter: {
      text: "Get exclusive weekly updates to Singapore's design scene, trend scoops, shopping and interior styling tips with our newsletter now. Join the tribe here.",
      buttonText: "SIGN UP",
      url: "/newsletter",
    },
    closeButton: {
      type: "icon",
      placement: "right",
      TextOrIcon: (
        <svg
          width="20"
          height="20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 1 .615 19.385M19 19.385.615 1"
            stroke="#000"
            strokeWidth="1.5"
          />
        </svg>
      ),
    },
    divider: {
      footer: false,
    },
  },
  socialMedia: {
    target: "_blank",
    icons: [
      {
        icon: Icons.appleIcon,
        name: "iOS App Store",
        href: "https://apps.apple.com/sg/app/home-decor-singapore/id537207561",
      },
      {
        icon: Icons.googlePlaystoreIcon,
        name: "Google Playstore",
        href: "https://play.google.com/store/apps/details?id=com.magzter.homeanddecorsingapore",
      },
      {
        icon: Icons.instagramIcon,
        name: "Instagram",
        href: "https://instagram.com/homeanddecor_sg/",
      },
      {
        icon: Icons.facebookIcon,
        name: "Facebook",
        href: "https://www.facebook.com/HomeandDecor.Singapore",
      },
      {
        icon: Icons.tiktokIcon,
        name: "Tiktok",
        href: "https://www.tiktok.com/@homeanddecor.sg",
      },
      {
        icon: Icons.pinterestIcon,
        name: "Pinterest",
        href: "https://www.pinterest.com/homeanddecorsg/",
      },
    ],
  },
};

export const hamburgermenuSvgSource = (
  <svg
    width="33"
    height="22"
    viewBox="0 0 33 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.09961 13.7559H31.6648"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M1.09961 21H31.6648"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M31.7627 6.95652L16.3814 2L1 6.95652"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
