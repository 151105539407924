import { configAboutUs } from "@pub/config";
import { AuthorProfile } from "@sphtech/dmg-design-system";
import {
  ResolvedCUEElement,
  ResovledAuthorProfileElement,
} from "@src/app/layouts/Article/elements";
import cx from "classnames";

import styles from "./authorblock.module.css";
type Props = {
  data: ResovledAuthorProfileElement[];
  profileHeadlineElement: ResolvedCUEElement;
};

export default function AuthorBlock({ data, profileHeadlineElement }: Props) {
  return (
    <div className={cx(styles.container, styles.gutter)}>
      <div className={styles.content}>
        <p className={styles.heading}>
          {profileHeadlineElement.type === "sub_head"
            ? profileHeadlineElement.additionalFields.subhead?.value
            : configAboutUs.profileBlock?.heading}
        </p>
        {data.map(
          (element, index) =>
            element.resolvedData && (
              <AuthorProfile
                key={index}
                {...element.resolvedData}
                link={element.resolvedData.urlPath}
              />
            ),
        )}
      </div>
    </div>
  );
}
